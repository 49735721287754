import React from "react";
import aboutMetadata from 'data/SingleProject/about.json';
import aboutMetadataRTL from 'data/SingleProject/about-rtl.json';

const About = ({ style = "4", rtl }) => {
  const metadata = rtl ? aboutMetadataRTL : aboutMetadata;

  return (
    <section className="about-app style-5">
      <div className="container">
        <div className="content text-center">
          <h1 className="color-000 mb-50">Internet - {  'Banking' }</h1>
          <div className="about-cards border-bottom brd-gray">
            
          </div>
          <div className="img-content pt-70 pb-70 border-bottom brd-gray overflow-hidden">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="img text-center">
                  <img src="/assets/img/single_project/2mobs.png" alt="" className="main-img" />
                  <img src="/assets/img/single_project/bubbls.png" alt="" className="bubbls" />
                  <img src="/assets/img/single_project/circle.png" alt="" className="circle" />
                </div>
                <div className="info mt-30">
                  <h5 className="lh-4">
                    { 'Perform banking activities and manage financial transactions online. Access Your bank accounts and conduct transactions via our Website or mobile app without visiting a physical branch.' }
                  </h5>
                  <a href="https://ibank.mybankone.com/mutualtrust" className="btn btn-icon-circle rounded-pill bg-blue7 fw-bold text-white me-4" target="_blank">
                    <small> Explore our Internet Banking<i className="fas fa-long-arrow-alt-right"></i> </small>
                  </a>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About